/* eslint-disable new-cap */
import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { VALIDATION_REGEX } from '../../../../utils/generic/constants';
import { isEqual, isEmpty } from 'lodash';
import {
  getClassNameForIngestion,
  convertDataForSelectDropdown,
  checkForIngestionAction,
} from '../../../../utils/generic/helper';
import { sitesUrl, GET } from '../../../../api';

export const validateForm = (values, isAddBtn) => {
  const { country, state, city, street1, geoLocation, postalCode } = values;
  let errors = {};
  if (street1 === '') {
    errors = {
      ...errors,
      street1: 'Please provide details for the street',
    };
  }
  if (isEmpty(country)) {
    errors = {
      ...errors,
      country: 'Please provide details for the country',
    };
  }
  if (postalCode && (postalCode.length > 10 || ['.', ','].some(v => postalCode.includes(v)))) {
    errors = {
      ...errors,
      postalCode: 'Postal Code is incorrect, check again',
    };
  }

  if (!isAddBtn) {
    if (isEmpty(state)) {
      errors = {
        ...errors,
        state: 'Please provide details for the state',
      };
    }

    if (isEmpty(city)) {
      errors = {
        ...errors,
        city: 'Please provide details for the city',
      };
    }
    if (geoLocation != null && geoLocation.length) {
      let regexToTest = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
      if (!regexToTest.test(geoLocation)) {
        errors = {
          ...errors,
          geoLocation: 'Please enter valid Longitude/Latitude',
        };
      }
    }
  }
  return errors;
};

export const validateOnChange = ({ values, errors }) => {
  for (let key in errors) {
    if (key === 'postalCode') {
      if (
        Object.prototype.hasOwnProperty.call(errors, key) &&
        !(values[key] && (values[key].length > 10 || ['.', ','].some(v => values[key].includes(v))))
      )
        delete errors[key];
    } else if (Object.prototype.hasOwnProperty.call(errors, key) && !isEmpty(values[key])) {
      delete errors[key];
    }
  }
  return errors;
};

export const getStateData = async value => {
  try {
    let stateResponse = await GET(`${sitesUrl}/api/State/Country/${value}`);
    let stateData = stateResponse.data.data;
    stateData = convertDataForSelectDropdown([...stateData], 'id', 'stateName');
    return stateData;
  } catch (e) {
    return [];
  }
};

export const getCityData = async value => {
  try {
    let cityResponse = await GET(`${sitesUrl}/api/City/State/${value}`);
    let cityData = cityResponse.data.data;
    cityData = convertDataForSelectDropdown([...cityData], 'id', 'cityName');
    return cityData;
  } catch (e) {
    return [];
  }
};

export const initialValues = {
  street1: '',
  street2: '',
  city: null,
  state: null,
  country: null,
  postalCode: '',
  phoneNumbers: [],
  faxNumbers: [],
  websites: [],
  emails: [],
  validationCode: '',
  isValidated: '',
  geoLocation: null,
};

export const layoutSchema = isEdit => ({
  layout: 'row',
  className: 'organization-address-form mt-10',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'row',
      size: 12,
      className: 'organization-address-innerone',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      header: 'ADD ADDRESS',
      headerProps: {
        fontStyle: 'bold',
        size: '16',
      },
      content: [
        {
          layout: 'street1',
          size: 2,
          className: '',
        },
        {
          layout: 'street2',
          size: 2,
          className: '',
        },
        {
          layout: 'country',
          size: 2,
          className: '',
        },
        {
          layout: 'state',
          size: 2,
          className: '',
        },
        {
          layout: 'city',
          size: 2,
          className: '',
        },

        {
          layout: 'postalCode',
          size: 2,
          className: '',
        },
        {
          layout: 'geoLocation',
          size: 2,
          className: '',
        },
        ...(isEdit
          ? [
              {
                layout: 'subRegion',
                size: 2,
                className: '',
              },
            ]
          : []),
      ],
    },
    {
      layout: 'row',
      size: 12,
      className: 'organization-address-innertwo',
      spacing: 1,
      justify: 'flex-start',
      alignItems: 'flex-start',
      header: 'ADD CONTACT INFO',
      headerProps: {
        fontStyle: 'bold',
        size: '16',
      },
      content: [
        {
          layout: 'phoneNumbers',
          size: 2,
          className: 'phone-no',
        },
        {
          layout: 'faxNumbers',
          size: 2,
          className: 'fax-no',
        },
        {
          layout: 'websites',
          size: 2,
          className: '',
        },
        {
          layout: 'emails',
          size: 2,
          className: '',
        },
        // {
        //   layout: 'validateButton',
        //   size: 1,
        //   className: 'add-button ml-30',
        // },
        {
          layout: 'addButton',
          size: 1,
          className: 'add-button',
        },
      ],
    },
  ],
});

export const formSchema = ({
  handleCountryChange,
  handleStateChange,
  handleValidate,
  filterCountry,
  filterState,
  filterCity,
  handleAddClick,
  ref: { phoneNumbers = null, faxNumbers = null, emails = null, websites = null } = {},
}) => ({
  street1: {
    name: 'street1',
    type: fieldTypes.text,
    label: 'STREET 1',
    options: [],
    props: {
      isMandatory: true,
      inputHeight: 'ht-lg',
    },
  },
  street2: {
    name: 'street2',
    type: fieldTypes.text,
    label: 'STREET 2',
    options: [],
    props: {
      inputHeight: 'ht-lg',
    },
  },
  country: {
    name: 'country',
    type: fieldTypes.select_async,
    label: 'COUNTRY',
    props: {
      isMandatory: true,
      handleSelectChange: handleCountryChange,
      height: 'ht-lg',
      onFilter: filterCountry,
      isSearchable: true,
      blurInputOnSelect: true,
      isClearable: true,
    },
    dependent: [
      {
        key: 'state',
        value: 'state',
      },
      {
        key: 'city',
        value: 'city',
      },
    ],
  },
  state: {
    name: 'state',
    type: fieldTypes.select_async,
    label: 'STATE',
    props: {
      handleSelectChange: handleStateChange,
      height: 'ht-lg',
      onFilter: filterState,
      isSearchable: true,
      blurInputOnSelect: true,
      isClearable: true,
    },
    dependent: [
      {
        key: 'city',
        value: 'city',
      },
    ],
  },
  city: {
    name: 'city',
    type: fieldTypes.select_async,
    label: 'CITY',
    props: {
      height: 'ht-lg',
      onFilter: filterCity,
      isSearchable: true,
      blurInputOnSelect: true,
      isClearable: true,
    },
  },
  postalCode: {
    name: 'postalCode',
    type: fieldTypes.text,
    label: 'POSTAL CODE',
    options: [],
    props: {
      inputHeight: 'ht-lg',
    },
  },
  geoLocation: {
    name: 'geoLocation',
    type: fieldTypes.text,
    label: 'LATITUDE,LONGITUDE',
    props: {
      disabled: false,
      inputHeight: 'ht-lg',
      validate: {
        rule: VALIDATION_REGEX.LATLONG,
        message: 'Please enter a valid latitude longitude',
      },
    },
  },
  websites: {
    name: 'websites',
    type: fieldTypes.entry,
    label: 'WEBSITE',
    options: [],
    props: {
      height: 'ht-lg',
      validate: {
        rule: VALIDATION_REGEX.WEBSITE,
        message: 'Please enter a valid website format',
      },
      ref: websites,
    },
  },
  phoneNumbers: {
    name: 'phoneNumbers',
    type: fieldTypes.entry,
    label: 'PHONE',
    options: [],
    props: {
      height: 'ht-lg',
      validate: {
        rule: VALIDATION_REGEX.PHONE,
        message: 'Please enter valid phone number',
      },
      ref: phoneNumbers,
    },
  },
  faxNumbers: {
    name: 'faxNumbers',
    type: fieldTypes.entry,
    label: 'FAX',
    options: [],
    props: {
      height: 'ht-lg',
      validate: {
        rule: VALIDATION_REGEX.PHONE,
        message: 'Please enter valid fax number',
      },
      ref: faxNumbers,
    },
  },
  emails: {
    name: 'emails',
    type: fieldTypes.entry,
    label: 'EMAIL',
    options: [],
    props: {
      height: 'ht-lg',
      validate: {
        rule: VALIDATION_REGEX.EMAIL,
        message: 'Please enter a valid email',
      },
      ref: emails,
    },
  },
  addButton: {
    name: 'addButton',
    type: fieldTypes.button,
    label: '+ ADD',
    props: {
      onClick: handleValidate,
    },
  },
});

export const columnDefs = ({
  handleChange,
  handleDelete,
  handleEdit,
  handleIngestionActions,
  disableEdit,
  gridData,
}) => [
  {
    headerName: 'PRIMARY ADDRESS',
    headerClass: 'primaryAddOrg',
    field: 'isPrimary',
    width: 250,
    cellClass: ({ data }) => `${getClassNameForIngestion(data.ingestionAction)}`,
    cellRenderer: 'checkBox',
    cellRendererParams: ({ api, rowIndex, value, data }) => {
      const updatedData = [];
      for (let index = 0; index < api.getDisplayedRowCount(); index += 1) {
        const rowData = api.getDisplayedRowAtIndex(index).data;
        const updatedRowData = {
          ...rowData,
          isPrimary: rowIndex === index,
        };
        updatedData.push(updatedRowData);
      }
      return {
        checked: value,
        onChange: () => handleChange(updatedData),
        name: 'radio-test',
        disabled: disableEdit || data.isDisabled,
      };
    },
  },
  {
    headerName: 'STREET 1',
    field: 'street1',
    width: 250,
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    sortable: true,
    cellRenderer: 'DefaultCellRender',
  },
  {
    headerName: 'STREET 2',
    field: 'street2',
    width: 250,
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    sortable: true,
    cellRenderer: 'DefaultCellRender',
  },
  {
    headerName: 'CITY',
    field: 'city.value',
    width: 200,
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    sortable: true,
    cellRenderer: 'DefaultCellRender',
  },
  {
    headerName: 'STATE',
    field: 'state.value',
    width: 200,
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    sortable: true,
    cellRenderer: 'DefaultCellRender',
  },
  {
    headerName: 'COUNTRY',
    field: 'country.value',
    width: 200,
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    sortable: true,
    cellRenderer: 'DefaultCellRender',
  },
  {
    headerName: 'POSTAL CODE',
    headerClass: 'postalCodeOrgAddress',
    field: 'postalCode',
    width: 200,
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    sortable: false,
    cellRenderer: 'DefaultCellRender',
  },
  {
    headerName: 'LATITUDE,LONGITUDE',
    field: 'geoLocation',
    width: 250,
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    sortable: true,
    cellRenderer: 'DefaultCellRender',
  },
  {
    headerName: 'PHONE',
    headerClass: 'phoneOrg',
    field: '',
    cellRenderer: 'ListView',
    width: 250,
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    cellRendererParams: ({ data }) => {
      return {
        list: data.phoneNumbers,
      };
    },
  },
  {
    headerName: 'FAX',
    headerClass: 'faxOrg',
    field: '',
    cellRenderer: 'ListView',
    width: 250,
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    cellRendererParams: ({ data }) => {
      return {
        list: data.faxNumbers,
      };
    },
  },
  {
    headerName: 'EMAIL',
    headerClass: 'emailOrg',
    field: '',
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    cellRenderer: 'ListView',
    cellRendererParams: ({ data }) => {
      return {
        list: data.emails,
      };
    },
  },
  {
    headerName: 'WEBSITE',
    headerClass: 'websiteOrg',
    field: '',
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    cellRenderer: 'Link',
    cellRendererParams: ({ data }) => {
      return {
        urlLink: data.websites,
        isDynamic: true,
      };
    },
  },
  {
    headerName: 'SUB REGION',
    headerClass: 'subRegion',
    field: 'subRegion',
    width: 200,
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
    sortable: false,
    cellRenderer: 'DefaultCellRender',
  },
  // {
  //   headerName: 'STATUS',
  //   field: 'validationCode',
  //   width: 200,
  //   cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
  //   cellRenderer: ({ data }) => {
  //     const code = data.validationCode ? data.validationCode : 'E';
  //     return `${code}`;
  //   },
  //   sortable: true,
  // },
  // {
  //   headerName: 'VALIDATED',
  //   headerClass: 'validatedOrg',
  //   field: 'isValidated',
  //   width: 150,
  //   cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction),
  //   cellRenderer: 'addressValidation',
  //   cellRendererParams: ({ data }) => {
  //     return {
  //       isValid: data.isValidated ? data.isValidated : false,
  //     };
  //   },
  // },
  {
    headerName: '',
    minWidth: 70,
    maxWidth: 100,
    field: '',
    cellClass: ({ data }) => `${getClassNameForIngestion(data.ingestionAction)}`,
    cellRenderer: 'actions',
    cellRendererParams: params => {
      const { rowIndex, data } = params;
      return {
        handleDelete: () => {
          handleDelete(data, rowIndex);
        },
        handleEdit: () => {
          handleEdit(data, rowIndex);
        },
        canDisableEdit: disableEdit || data.isDisabled,
        canDisableDelete: disableEdit || data.isDisabled,
      };
    },
  },
  {
    headerName: '',
    minWidth: 100,
    maxWidth: 100,
    field: '',
    hide: !checkForIngestionAction(gridData, true),
    cellRenderer: 'ingestionActions',
    cellRendererParams: ({ data: rowData }) => {
      return {
        isDisabled: disableEdit,
        ingestionAction: rowData ? rowData.ingestionAction : 'none',
        handleIngestionActions: option => handleIngestionActions(option, rowData),
      };
    },
  },
];
export const validationMessages = {
  PRIMARY_DELETE: 'Primary address cannot be deleted',
  PRIMARY_NOT_EXISTS: 'Please select the Primary address',
  EMPTY_RECORD: 'Please add atleast one primary address',
};
export const doesAddressExists = (data, values) => {
  let {
    street1,
    street2,
    city,
    state,
    country,
    postalCode,
    // phoneNumbers,
    // faxNumbers,
    // emails,
    // websites,
    // latitude,
    // longitude,
    // validationCode,
    // validationDescription,
  } = values;
  let isDataExists = false;
  data.length &&
    data.forEach(c => {
      if (
        isEqual(c.street1, street1) &&
        isEqual(c.street2, street2) &&
        idCheck(c.city, city) &&
        idCheck(c.state, state) &&
        idCheck(c.country, country) &&
        isEqual(c.postalCode, postalCode)
        // isEqual(c.latitude, latitude) &&
        // isEqual(c.longitude, longitude) &&
        // isEqual(c.validationCode, validationCode) &&
        // isEqual(c.validationDescription, validationDescription) &&
        // isEqual(sortBy(c.emails), sortBy(emails)) &&
        // isEqual(sortBy(c.faxNumbers), sortBy(faxNumbers)) &&
        // isEqual(sortBy(c.websites), sortBy(websites)) &&
        // isEqual(sortBy(c.phoneNumbers), sortBy(phoneNumbers))
      ) {
        isDataExists = true;
      }
    });
  return isDataExists;
};

const idCheck = (ref, act) => {
  if (isEmpty(ref) && isEmpty(act)) {
    return true;
  } else if (!isEmpty(ref) && !isEmpty(act)) {
    return isEqual(ref.id, act.id);
  } else {
    return false;
  }
};

export const mapGeoLocation = contactInfo => {
  const updatedRowList = [];
  contactInfo.forEach(element => {
    if (element.geoLocation) {
      updatedRowList.push(element);
    } else {
      const { latitude, longitude } = element;
      const geoLocation = `${latitude ? latitude : ''}${longitude ? `,${longitude}` : ''}`;
      updatedRowList.push({
        ...element,
        geoLocation: geoLocation ? geoLocation : null,
      });
    }
  });
  return updatedRowList;
};
