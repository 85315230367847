import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../../../framework/link';
import { useHistory } from 'react-router-dom';

const TableLink = ({ link, navigationUrl }) => {
  const history = useHistory();

  const navigate = () => {
    history.push(navigationUrl);
  };
  return <Link onClick={navigate} link={link} />;
};

TableLink.propTypes = {
  link: PropTypes.string.isRequired,
  navigationUrl: PropTypes.string.isRequired,
};

export default React.memo(TableLink);
