import React from 'react';
import PropTypes from 'prop-types';
import InputIcon from '../inputs/InputIcon';
import { statusColorRules } from '../../utils/generic/constants';

const ProductionStatus = ({ prodStatus, prodStsColorRules: colorRules, isRegIntel }) => {
  // console.log('Producetion status', { prodStatus, prodStsColorRules: colorRules, isRegIntel });
  let prodId = prodStatus;

  const prodStsColorRules = isRegIntel ? statusColorRules : colorRules;

  if (typeof prodStatus === 'string') {
    prodId = Number(prodStatus);
  }
  if (typeof prodStatus === 'object') {
    const { id = 0 } = { ...prodStatus };
    prodId = id;
  }
  if (prodId || prodId === 0) {
    let color = '';
    if (prodStsColorRules[prodId]) {
      prodStsColorRules.map(clr => {
        if (clr.id === prodId) {
          color = clr.iconColor;
        }
      });
    } else {
      color = 'blue';
    }
    return <InputIcon color={color} />;
  }
  return '';
};

ProductionStatus.propTypes = {
  prodStatus: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  prodStsColorRules: PropTypes.array.isRequired,
};

ProductionStatus.defaultProps = {
  prodStsColorRules:
    localStorage.getItem('usergroup') == 'RegIntelUser'
      ? [
        { id: 0, display: 'Draft', iconColor: 'red' },
        { id: 1, display: 'Validated', iconColor: 'orange' },
        { id: 2, display: 'Published', iconColor: 'green' },
        { id: 3, display: 'Out of Scope', iconColor: 'black' },
      ]
      : [
        { id: 3, display: 'Pending Review', iconColor: 'yellow' },
        { id: 2, display: 'Out of scope', iconColor: 'black' },
        { id: 0, display: 'Out of production', iconColor: 'red' },
        { id: 4, display: 'Data check', iconColor: 'orange' },
        { id: 1, display: 'In Production', iconColor: 'green' },
        { id: 5, display: 'External Source', iconColor: 'blue' },
      ],
};

export default React.memo(ProductionStatus);
