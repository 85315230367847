/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';

import {
  InputButton,
  InputRadioOptions,
  InputTextField,
  InputSelect,
  InputDatePicker,
  InputTextArea,
  InputTimePicker,
  InputEntries,
  InputComboEntries,
  InputSelectAsync,
  InputSelectTreeAsync,
  InputTextAreaAdvanced,
  InputSelectGrouping,
  InputCustomFormatDatePicker,
  InputDateRange,
  InputCheckbox,
  InputHyperlink,
  InputTextRange,
  InputYearRange,
  InputMultiSelectAsync,
  InputNumber,
  InputRadio,
  InputFile,
  InputLabel,
  InputPhoneWithCountryCode,
  InputAddIcons,
  MdEditor,
  InputSelectTimezone,
} from '../../inputs';
import TextArea from '../../inputs/textArea';
import { fieldTypes } from '../utils';
import CountryMultiselectDropdown from '../../countryMultiselectDropdown';
import DNADataGrid from '../../dnaDataGrid';
import { Divider } from '@material-ui/core';
import './index.scss';

const FormBuilderInputs = ({
  attributes: {
    layout,
    formSchema,
    formikProps: { values, errors, touched, setFieldValue },
    disableControls,
  },
}) => {
  const {
    name,
    id,
    type,
    label,
    labelSuffix,
    options,
    props,
    dependent,
    isModal,
    drugValue,
    masterData,
    showDefaultSelectetValues,
  } = formSchema[layout];

  let result = null;
  switch (type) {
    case fieldTypes.text:
      {
        const { handleInputChange, disabled, ...otherProps } = { ...props };
        result = (
          <InputTextField
            name={name}
            id={id}
            value={values[layout] ? values[layout].toString() : ''}
            onChange={({ target: { value } }) => {
              if (handleInputChange) {
                handleInputChange({ key: name, value });
              }
              setFieldValue(name, value);
            }}
            label={label}
            labelSuffix={labelSuffix}
            error={Boolean(errors[layout]) && touched[layout]}
            helperText={touched[layout] ? errors[layout] : null}
            disabled={disableControls || disabled}
            {...otherProps}
          />
        );
      }
      break;

    case fieldTypes.phoneNumberWithCountryCode:
      {
        const { disabled, ...otherProps } = { ...props };
        result = (
          <InputPhoneWithCountryCode
            name={name}
            label={label}
            value={values[layout]}
            isError={Boolean(errors[layout]) && touched[layout]}
            errorMessage={touched[layout] ? errors[layout] : null}
            onChange={data => setFieldValue(name, data)}
            disabled={disabled || disableControls}
            {...otherProps}
          />
        );
      }
      break;

    case fieldTypes.textArea:
      {
        const { disabled, ...otherProps } = { ...props };
        result = (
          <InputTextArea
            name={name}
            label={label}
            value={values[layout]}
            isError={Boolean(errors[layout]) && touched[layout]}
            errorMessage={touched[layout] ? errors[layout] : null}
            onChange={e => setFieldValue(name, e.target.value)}
            disabled={disabled || disableControls}
            {...otherProps}
          />
        );
      }
      break;
    case fieldTypes.text_area:
      {
        const { disabled, ...otherProps } = { ...props };
        result = (
          <TextArea
            name={name}
            label={label}
            value={values[layout]}
            isError={Boolean(errors[layout]) && touched[layout]}
            errorMessage={touched[layout] ? errors[layout] : null}
            onChange={e => setFieldValue(name, e.target.value)}
            disabled={disabled || disableControls}
            {...otherProps}
          />
        );
      }
      break;
    case fieldTypes.custom_radio:
      {
        const { disabled, handleChange = () => {}, ...otherProps } = { ...props };
        result = (
          <InputRadioOptions
            name={name}
            value={values[layout]}
            data={options}
            color="gray"
            label={label}
            disableRules={[]}
            handleChange={data => {
              setFieldValue(name, data);
              handleChange(data);
            }}
            isError={Boolean(errors[layout]) && touched[layout]}
            errorMessage={touched[layout] ? errors[layout] : null}
            disabled={disabled || disableControls}
            {...otherProps}
          />
        );
      }
      break;

    case fieldTypes.button:
      {
        const { disabled, ...otherProps } = { ...props };
        result = (
          <InputButton
            isDisabled={disableControls || disabled}
            type={type}
            variant="contained"
            color="secondary"
            text={label}
            {...otherProps}
          />
        );
      }
      break;

    case fieldTypes.customButton:
      {
        const { disabled, overrideReadOnly, ...otherProps } = { ...props };
        result = (
          <InputButton
            isDisabled={overrideReadOnly ? !overrideReadOnly : disableControls || disabled}
            type={type}
            variant="contained"
            color="secondary"
            text={label}
            {...otherProps}
          />
        );
      }
      break;

    case fieldTypes.select:
      {
        const {
          handleSelectChange,
          dependents,
          isDisabled,
          handleChangeForValidation,
          ...otherSelectProps
        } = { ...props };
        result = (
          <InputSelect
            name={name}
            defaultValue={values[layout]}
            label={label}
            data={options}
            error={Boolean(errors[layout]) && Boolean(touched[layout])}
            helperText={touched[layout] ? errors[layout] : null}
            onChange={async data => {
              if (dependent) {
                const { key, value } = dependent;
                setFieldValue(key, data && data[value] ? data[value] : '');
              }
              if (dependents && dependents.length > 0) {
                dependents.forEach(({ fieldName, fieldKey }) => {
                  setFieldValue(fieldName, data && data[fieldKey] ? data[fieldKey] : null);
                });
              }
              setFieldValue(name, data);
              if (handleChangeForValidation) {
                handleChangeForValidation(data);
              }
              if (handleSelectChange) {
                await handleSelectChange(data);
              }
              setFieldValue(name, data);
            }}
            isDisabled={isDisabled || disableControls}
            isSearchable={options?.length > 10}
            {...otherSelectProps}
          />
        );
      }
      break;

    case fieldTypes.select_tag:
      {
        const { isDisabled, ...otherProps } = { ...props };
        result = (
          <InputSelect
            name={name}
            isMulti
            isTagInput
            handleCustomupdate={data => setFieldValue(name, data)}
            data={options}
            defaultValuesCustom={values[layout] ? values[layout] : []}
            label={label}
            error={Boolean(errors[layout]) && touched[layout]}
            helperText={touched[layout] ? errors[layout] : null}
            isDisabled={isDisabled || disableControls}
            {...otherProps}
            isSearchable={options?.length > 10}
          />
        );
      }
      break;

    case fieldTypes.countryMultiselect:
      {
        const { isDisabled, ...otherProps } = { ...props };
        result = (
          <CountryMultiselectDropdown
            isModal={isModal}
            name={name}
            masterData={masterData}
            showDefaultSelectetValues={showDefaultSelectetValues}
            label={label}
            data={options}
            handleChange={data => setFieldValue(name, data)}
            error={Boolean(errors[layout]) && touched[layout]}
            helperText={touched[layout] ? errors[layout] : null}
            isDisabled={isDisabled || disableControls}
            {...otherProps}
            isSearchable={options?.length > 10}
          />
        );
      }
      break;

    case fieldTypes.date:
      {
        const { disabled, ...otherProps } = { ...props };
        result = (
          <InputDatePicker
            name={name}
            isError={Boolean(errors[layout]) && touched[layout]}
            errorMessage={touched[layout] ? errors[layout] : null}
            value={values[layout]}
            label={label}
            onChange={data => setFieldValue(name, data ? new Date(data) : null)}
            disabled={disabled || disableControls}
            {...otherProps}
          />
        );
      }
      break;
    case fieldTypes.dateRange:
      {
        const { disabled, ...otherProps } = { ...props };
        result = (
          <InputDateRange
            name={name}
            isError={Boolean(errors[layout]) && touched[layout]}
            errorMessage={touched[layout] ? errors[layout] : null}
            value={values[layout]}
            label={label}
            onChange={data => setFieldValue(name, data)}
            disabled={disabled || disableControls}
            {...otherProps}
          />
        );
      }
      break;
    case fieldTypes.yearRange:
      {
        const { disabled, ...otherProps } = { ...props };
        result = (
          <InputYearRange
            name={name}
            isError={Boolean(errors[layout]) && touched[layout]}
            errorMessage={touched[layout] ? errors[layout] : null}
            value={values[layout]}
            label={label}
            onChange={data => setFieldValue(name, data)}
            disabled={disabled || disableControls}
            {...otherProps}
          />
        );
      }
      break;
    case fieldTypes.textRange:
      {
        const { disabled, ...otherProps } = { ...props };
        result = (
          <InputTextRange
            name={name}
            isError={Boolean(errors[layout]) && touched[layout]}
            errorMessage={touched[layout] ? errors[layout] : null}
            value={values[layout]}
            label={label}
            onChange={data => {
              setFieldValue(name, data);
            }}
            disabled={disabled || disableControls}
            {...otherProps}
          />
        );
      }
      break;
    case fieldTypes.customDate:
      {
        const { disabled, ...otherProps } = { ...props };
        result = (
          <InputCustomFormatDatePicker
            name={name}
            isError={Boolean(errors[layout]) && touched[layout]}
            errorMessage={touched[layout] ? errors[layout] : null}
            value={values[layout]}
            label={label}
            onChange={data => setFieldValue(name, data ? data : null)}
            disabled={disabled || disableControls}
            {...otherProps}
          />
        );
      }
      break;
    case fieldTypes.entry:
      {
        const { disabled, ...otherProps } = { ...props };
        result = (
          <InputEntries
            name={name}
            error={Boolean(errors[layout]) && touched[layout]}
            helperText={touched[layout] ? errors[layout] : null}
            entries={values[layout]}
            label={label}
            onEntry={data => setFieldValue(name, data)}
            valueCheck={values[dependent]}
            disabled={disabled || disableControls}
            {...otherProps}
          />
        );
      }
      break;
    case fieldTypes.comboEntry:
      {
        const { disabled, ...otherProps } = { ...props };
        result = (
          <InputComboEntries
            name={name}
            entries={values[layout]}
            label={label}
            options={options}
            onEntry={data => setFieldValue(name, data)}
            disabled={disabled || disableControls}
            {...otherProps}
            isSearchable={options?.length > 10}
          />
        );
      }
      break;

    case fieldTypes.time:
      {
        const { disabled, ...otherProps } = { ...props };
        result = (
          <InputTimePicker
            name={name}
            isError={Boolean(errors[layout]) && touched[layout]}
            errorMessage={touched[layout] ? errors[layout] : null}
            value={values[layout]}
            label={label}
            onChange={data => setFieldValue(name, data ? new Date(data) : null)}
            disabled={disabled || disableControls}
            {...otherProps}
          />
        );
      }
      break;

    case fieldTypes.select_async:
      {
        const {
          onFilter,
          dependents,
          isDisabled,
          fieldName,
          handleSelectChange,
          onAddAncillary,
          hasAddFeature,
          ...otherProps
        } = props;
        const dropdownValueProps = props.showSelectedData
          ? {
              value: null,
              selected: values[layout],
            }
          : {
              value: values[layout],
              selected: [],
            };
        result = (
          <>
            <InputSelectAsync
              name={name}
              formValues={values}
              onChange={async data => {
                if (dependents && dependents.length > 0) {
                  dependents.forEach(({ fieldName, fieldKey }) => {
                    setFieldValue(fieldName, data && data[fieldKey] ? data[fieldKey] : null);
                  });
                }
                if (handleSelectChange) {
                  await handleSelectChange(data);
                }
                setFieldValue(name, data);
              }}
              label={label}
              onFilter={async value => {
                const filteredData = await onFilter(value, options, fieldName);
                return filteredData;
              }}
              isError={Boolean(errors[layout]) && touched[layout]}
              errorMessage={touched[layout] ? errors[layout] : null}
              isDisabled={isDisabled || disableControls}
              {...otherProps}
              {...dropdownValueProps}
            />
            {hasAddFeature && (
              <InputButton
                variant="contained"
                text={name === 'company' ? `Request ${name}` : `+ ${name}`}
                buttonSize="xs"
                onClick={onAddAncillary}
              />
            )}
          </>
        );
      }
      break;
    case fieldTypes.select_tree_async: {
      {
        const {
          onFilter,
          dependents,
          isDisabled,
          fieldName,
          handleSelectChange,
          onAddAncillary,
          hasAddFeature,
          ...otherProps
        } = props;
        const dropdownValueProps = props.showSelectedData
          ? {
              value: null,
              selected: values[layout],
            }
          : {
              value: values[layout],
              selected: [],
            };
        result = (
          <>
            <InputSelectTreeAsync
              name={name}
              onChange={async data => {
                if (dependents && dependents.length > 0) {
                  dependents.forEach(({ fieldName, fieldKey }) => {
                    setFieldValue(fieldName, data && data[fieldKey] ? data[fieldKey] : null);
                  });
                }
                if (handleSelectChange) {
                  await handleSelectChange(data);
                }
                setFieldValue(name, data);
              }}
              label={label}
              onFilter={async value => {
                const filteredData = await onFilter(value, options, fieldName);
                return filteredData;
              }}
              isError={Boolean(errors[layout]) && touched[layout]}
              errorMessage={touched[layout] ? errors[layout] : null}
              isDisabled={isDisabled || disableControls}
              {...otherProps}
              {...dropdownValueProps}
            />
            {hasAddFeature && (
              <InputButton
                variant="contained"
                text={name === 'company' ? `Request ${name}` : `+ ${name}`}
                buttonSize="xs"
                onClick={onAddAncillary}
              />
            )}
          </>
        );
      }
      break;
    }
    case fieldTypes.multi_select_async:
      {
        const {
          onFilter,
          dependents,
          isDisabled,
          fieldName,
          handleSelectChange,
          onAddAncillary,
          hasAddFeature,
          ...otherProps
        } = props;
        const dropdownValueProps = props.showSelectedData
          ? {
              value: null,
              selected: values[layout],
            }
          : {
              value: values[layout],
              selected: [],
            };
        result = (
          <>
            <InputMultiSelectAsync
              name={name}
              onChange={async data => {
                if (dependents && dependents.length > 0) {
                  dependents.forEach(({ fieldName, fieldKey }) => {
                    setFieldValue(fieldName, data && data[fieldKey] ? data[fieldKey] : null);
                  });
                }
                if (handleSelectChange) {
                  await handleSelectChange(data);
                }
                setFieldValue(name, data);
              }}
              label={label}
              onFilter={async value => {
                const filteredData = await onFilter(value, options, fieldName);
                return filteredData;
              }}
              drugValue={drugValue}
              isError={Boolean(errors[layout]) && touched[layout]}
              errorMessage={touched[layout] ? errors[layout] : null}
              isDisabled={isDisabled || disableControls}
              {...otherProps}
              {...dropdownValueProps}
            />
            {hasAddFeature && (
              <InputButton
                variant="contained"
                text={name === 'company' ? `Request ${name}` : `+ ${name}`}
                buttonSize="xs"
                onClick={onAddAncillary}
              />
            )}
          </>
        );
      }
      break;

    case fieldTypes.textAreaAdvanced:
      {
        const { isDisabled, ...otherProps } = { ...props };
        result = (
          <InputTextAreaAdvanced
            name={name}
            label={label}
            text={values[layout]}
            isError={Boolean(errors[layout]) && touched[layout]}
            errorMessage={touched[layout] ? errors[layout] : null}
            setFieldValue={setFieldValue}
            isDisabled={isDisabled || disableControls}
            {...otherProps}
          />
        );
      }
      break;

    case fieldTypes.selectGroup:
      {
        const { isDisabled, handleSelectChange, ...otherProps } = { ...props };
        result = (
          <InputSelectGrouping
            name={name}
            label={label}
            labelSuffix={labelSuffix}
            options={options}
            error={Boolean(errors[layout]) && Boolean(touched[layout])}
            helperText={touched[layout] ? errors[layout] : null}
            onChange={async data => {
              setFieldValue(name, data);
              if (handleSelectChange) {
                await handleSelectChange(data);
              }
            }}
            isDisabled={isDisabled || disableControls}
            selectedOptions={values[layout]}
            {...otherProps}
          />
        );
      }
      break;

    case fieldTypes.checkbox:
      {
        const { isDisabled, isMandatory, onChange, hideLabel, ...otherProps } = { ...props };
        result = (
          <>
            {label && (
              <div>
                <InputLabel labelFor="inputLabel" text={label} isMandatory={isMandatory} />
                {Boolean(errors[layout]) && touched[layout] && (
                  <p className="error-text">{errors[layout]}</p>
                )}
              </div>
            )}
            <InputCheckbox
              name={name}
              label={hideLabel ? '' : label}
              checked={values[layout]}
              disabled={isDisabled || disableControls}
              onChange={({ isChecked }) => {
                setFieldValue(name, isChecked);
                if (onChange) {
                  onChange({ [name]: isChecked });
                }
              }}
              options={values[layout] ? values[layout] : options}
              {...otherProps}
            />
          </>
        );
      }
      break;
    case fieldTypes.hyperlink:
      {
        const { ...otherProps } = { ...props };
        result = (
          <InputHyperlink
            name={name}
            value={values[layout] ? values[layout] : null}
            label={label}
            {...otherProps}
          />
        );
      }
      break;

    case fieldTypes.selectNumber:
      {
        const { ...otherProps } = { ...props };
        result = <InputNumber label={label} {...otherProps} disabled={disableControls} />;
      }
      break;

    case fieldTypes.icons:
      {
        const { ...otherProps } = { ...props };
        result = <InputAddIcons {...otherProps} disabled={disableControls} />;
      }
      break;
    case fieldTypes.attachFile:
      {
        const { ...otherProps } = { ...props };
        result = (
          <InputFile
            label={label}
            isError={Boolean(errors[layout]) && touched[layout]}
            errorMessage={touched[layout] ? errors[layout] : null}
            disabled={disableControls}
            {...otherProps}
          />
        );
      }
      break;

    case fieldTypes.dndDataGrid:
      {
        const { isMandatory, gap, isError, errorMsg, ...otherProps } = props;
        result = (
          <div style={{ marginBottom: gap || '2rem' }} className="dna-data-grid">
            {label ? (
              <div>
                <InputLabel labelFor="inputLabel" text={label} isMandatory={isMandatory} />
                {Boolean(errors[layout]) && touched[layout] && (
                  <p className="error-text">{errors[layout]}</p>
                )}
                <Divider />
              </div>
            ) : (
              <>
                {Boolean(errors[layout]) && touched[layout] && (
                  <p className="error-text">{errors[layout]}</p>
                )}
              </>
            )}
            <DNADataGrid
              getRowClass={({ data }) => (data && data.isDisabled ? 'disable-grid-row' : '')}
              {...otherProps}
            />
          </div>
        );
      }
      break;

    case fieldTypes.radio:
      {
        const { isMandatory, gap, className, disabled, ...otherProps } = props;
        result = (
          <div style={{ marginBottom: gap }} className={className}>
            {label && (
              <div>
                <InputLabel labelFor="inputLabel" text={label} isMandatory={isMandatory} />
                <Divider />
              </div>
            )}
            <InputRadio
              value={values[layout] ? values[layout] : null}
              options={options}
              onChange={e => setFieldValue(name, e.target.value)}
              isError={Boolean(errors[layout]) && touched[layout]}
              errorMessage={touched[layout] ? errors[layout] : null}
              disabled={disableControls || disabled}
              {...otherProps}
            />
            {Boolean(errors[layout]) && touched[layout] && (
              <p className="error-text">{errors[layout]}</p>
            )}
          </div>
        );
      }
      break;
    case fieldTypes.select_timezone:
      {
        const { disabled, ...otherProps } = { ...props };
        result = (
          <InputSelectTimezone
            label={label}
            value={values[layout]}
            isError={Boolean(errors[layout]) && touched[layout]}
            errorMessage={touched[layout] ? errors[layout] : null}
            onChange={data => setFieldValue(name, data)}
            disabled={disabled || disableControls}
            {...otherProps}
          />
        );
      }
      break;
    case fieldTypes.mdEditor:
      {
        const { disabled, ...otherProps } = { ...props };
        result = (
          <MdEditor
            name={name}
            label={label}
            value={values[layout]}
            isError={Boolean(errors[layout]) && touched[layout]}
            errorMessage={touched[layout] ? errors[layout] : null}
            onChange={value => setFieldValue(name, value)}
            disabled={disabled || disableControls}
            {...otherProps}
          />
        );
      }
      break;
    default:
      break;
  }
  return result;
};

FormBuilderInputs.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export default FormBuilderInputs;
