import { TEXT_LIMIT } from '../../../../utils/generic/constants';
import {
  createCheckIsModalSelected,
  getCurrentTab,
  isCheckRadioOptionSelected,
} from '../../../../utils/generic/helper';
import testData from './data.json';

export const modalOptions = [
  'mandatoryDisclosure',
  'voluntaryDisclosure',
  'notCoveredInRegulation',
];

export const checkIsModalSelected = (arr, isMandatory) =>
  createCheckIsModalSelected(modalOptions)(arr, isMandatory);

export const modalDateOptions = ['beforeDate', 'onDate', 'afterDate'];

export const checkIsModalDateSelected = arr => createCheckIsModalSelected(modalDateOptions)(arr);

export const validate = (currentValues, isValidate, isOtherTab, tab) => {
  const errors = {};
  const values = !isOtherTab
    ? currentValues
    : currentValues.resultDisclosure?.find(value => value.tabId === tab.tabId) || testData;
  if (values) {
    const isTrue = key => [1, true].includes(values[key]);
    const validateDesc = key => {
      if (!values[key]?.trim()) {
        errors[key] = 'Please enter description';
      }
    };

    const otherInfo = values.otherInfo?.replace(/(\r\n|\n|\r)/gm, '').trim();
    const timingDifferenceDesc = values.timingDifferenceDesc?.replace(/(\r\n|\n|\r)/gm, '').trim();
    const alternateTrialMilestone = values.alternateTrialMilestone
      ?.replace(/(\r\n|\n|\r)/gm, '')
      .trim();
    const timingResultDisclosed = values.timingResultDisclosed
      ?.replace(/(\r\n|\n|\r)/gm, '')
      .trim();
    const timingExceptions = values.timingExceptions?.replace(/(\r\n|\n|\r)/gm, '').trim();
    const doesRegulationRequireDisclosureDesc = values.doesRegulationRequireDisclosureDesc
      ?.replace(/(\r\n|\n|\r)/gm, '')
      .trim();
    const productApprovalStatusDesc = values.productApprovalStatusDesc
      ?.replace(/(\r\n|\n|\r)/gm, '')
      .trim();
    const trialStatusDesc = values.trialStatusDesc?.replace(/(\r\n|\n|\r)/gm, '').trim();
    const optionToDelayDesc = values.optionToDelayDesc?.replace(/(\r\n|\n|\r)/gm, '').trim();
    const otherUpdateDueDesc = values.otherUpdateDueDesc?.replace(/(\r\n|\n|\r)/gm, '').trim();
    const otherUpdateTypeDesc = values.otherUpdateTypeDesc?.replace(/(\r\n|\n|\r)/gm, '').trim();
    const resultDisclosureRequirementInternalComments = values.resultDisclosureRequirementInternalComments
      ?.replace(/(\r\n|\n|\r)/gm, '')
      .trim();
    const resultPostingComments = values.resultPostingComments
      ?.replace(/(\r\n|\n|\r)/gm, '')
      .trim();
    if (values.otherInfo?.trim() && otherInfo.length > TEXT_LIMIT[3000]) {
      errors.otherInfo = 'Please enter only 3000 characters';
    }
    if (values.resultPostingComments && resultPostingComments.length > TEXT_LIMIT[3000]) {
      errors.resultPostingComments = 'Please enter only 3000 characters';
    }
    if (values.timingExceptions?.trim() && timingExceptions.length > TEXT_LIMIT[3000]) {
      errors.timingExceptions = 'Please enter only 3000 characters';
    }
    if (values.timingDifferenceDesc?.trim() && timingDifferenceDesc.length > TEXT_LIMIT[7000]) {
      errors.timingDifferenceDesc = 'Please enter only 7000 characters';
    }
    if (
      values.alternateTrialMilestone?.trim() &&
      alternateTrialMilestone.length > TEXT_LIMIT[3000]
    ) {
      errors.alternateTrialMilestone = 'Please enter only 3000 characters';
    }
    if (values.timingResultDisclosed?.trim() && timingResultDisclosed.length > TEXT_LIMIT[400]) {
      errors.timingResultDisclosed = 'Please enter only 400 characters';
    }
    if (
      values.doesRegulationRequireDisclosureDesc?.trim() &&
      doesRegulationRequireDisclosureDesc.length > TEXT_LIMIT[7000]
    ) {
      errors.doesRegulationRequireDisclosureDesc = 'Please enter only 7000 characters';
    }
    if (
      values.productApprovalStatusDesc?.trim() &&
      productApprovalStatusDesc.length > TEXT_LIMIT[3000]
    ) {
      errors.productApprovalStatusDesc = 'Please enter only 3000 characters';
    }
    if (values.trialStatusDesc?.trim() && trialStatusDesc.length > TEXT_LIMIT[3000]) {
      errors.trialStatusDesc = 'Please enter only 3000 characters';
    }
    if (values.optionToDelayDesc?.trim() && optionToDelayDesc.length > TEXT_LIMIT[3000]) {
      errors.optionToDelayDesc = 'Please enter only 3000 characters';
    }
    if (values.otherUpdateDueDesc?.trim() && otherUpdateDueDesc.length > TEXT_LIMIT[3000]) {
      errors.otherUpdateDueDesc = 'Please enter only 3000 characters';
    }
    if (values.otherUpdateTypeDesc?.trim() && otherUpdateTypeDesc.length > TEXT_LIMIT[400]) {
      errors.otherUpdateTypeDesc = 'Please enter only 400 characters';
    }
    if (
      values.resultDisclosureRequirementInternalComments?.trim() &&
      resultDisclosureRequirementInternalComments.length > TEXT_LIMIT[7000]
    ) {
      errors.resultDisclosureRequirementInternalComments = 'Please enter only 7000 characters';
    }
    if (
      values?.areThereAnyExceptionsDesc?.trim() &&
      values?.areThereAnyExceptionsDesc?.trim().length > TEXT_LIMIT[500]
    ) {
      errors.areThereAnyExceptionsDesc = 'Please enter only 500 characters';
    }
    if (
      values?.trialResultSubmission?.trim() &&
      values?.trialResultSubmission?.trim().length > TEXT_LIMIT[1000]
    ) {
      errors.trialResultSubmission = 'Please enter only 1000 characters';
    }
    if (
      values?.exceptionsOnTypesOfResultDesc?.trim() &&
      values?.exceptionsOnTypesOfResultDesc?.trim().length > TEXT_LIMIT[1000]
    ) {
      errors.exceptionsOnTypesOfResultDesc = 'Please enter only 1000 characters';
    }
    if (
      values?.justificationRequiredDetails?.trim() &&
      values?.justificationRequiredDetails?.trim().length > TEXT_LIMIT[1000]
    ) {
      errors.justificationRequiredDetails = 'Please enter only 1000 characters';
    }
    if (isValidate) {
      if (values.isResultsPosted) {
        if (values.resultsPosted === null) {
          errors.resultsPosted = 'Please select the results posted option';
        }
        if (values.isResultUpdatePosted) {
          if (values.resultUpdatePosted === null) {
            errors.resultUpdatePosted = 'Please select results update option';
          }
        }
      }
      if (currentValues.isMandatory) {
        if (values.isResultsPosted === null) {
          errors.isResultsPosted = 'Please select results need to be posted';
        }
        if (values.isResultsPosted) {
          if (values?.resultDisclosureFormats?.length > 0) {
            if (isCheckRadioOptionSelected(values, 'resultDisclosure')) {
              errors.resultDisclosure = 'Please select the format';
            }
          }
          if (values?.resultsDisclosureDueDate?.length > 0) {
            if (isCheckRadioOptionSelected(values, 'resultsDisclosureDueDate')) {
              errors.resultsDisclosureDueDate = 'Please select the format';
            }
          }
          if (values.timingIsDifference === null) {
            errors.timingIsDifference = 'Please select timing is difference';
          }
          if (!values.timingResultDisclosed?.trim()) {
            errors.timingResultDisclosed = 'Please enter timing results disclosed';
          }
          if (!values.timingExceptions?.trim()) {
            errors.timingExceptions = 'Please enter timing exceptions';
          }
          if (values.doesRegulationRequireDisclosure === null) {
            errors.doesRegulationRequireDisclosure =
              'Please select does regulation require disclosure';
          }
          if (values.productApprovalStatus === null) {
            errors.productApprovalStatus = 'Please select product approval status';
          }
          if (values.trialStatus === null) {
            errors.trialStatus = 'Please select trial status';
          }
          if (values.isOptionToDelay === null) {
            errors.isOptionToDelay = 'Please select is option to delay';
          }
          if (values.isResultPublicalyAvailable === null) {
            errors.isResultPublicalyAvailable = 'Please select is result publicaly available';
          }
          if ([1, 2].includes(tab?.tabId) && values.isThereOtherStudyTypeOrPhase === null) {
            errors.isThereOtherStudyTypeOrPhase =
              'Please select is there other study type or phases';
          }
          if (isTrue('timingIsDifference')) {
            validateDesc('timingDifferenceDesc');
          }
          if (isTrue('doesRegulationRequireDisclosure')) {
            validateDesc('doesRegulationRequireDisclosureDesc');
          }
          if (isTrue('productApprovalStatus')) {
            validateDesc('productApprovalStatusDesc');
          }
          if (isTrue('trialStatus')) {
            validateDesc('trialStatusDesc');
          }
          if (isTrue('isOptionToDelay')) {
            validateDesc('optionToDelayDesc');
          }
        }
      }

      if (isOtherTab) {
        if (values.isResultsPosted) {
          if (
            !checkIsModalSelected(
              getCurrentTab(currentValues.resultDisclosureFormats, tab),
              currentValues.isMandatory,
            )
          ) {
            errors.resultDisclosure = 'Please select result disclosure formats';
          }
          if (
            !checkIsModalDateSelected(
              getCurrentTab(currentValues.resultsDisclosureDueDate, tab),
              currentValues.isMandatory,
            )
          ) {
            errors.resultsDisclosureDueDate = 'Please select result disclosure due date';
          }
          if (currentValues.isMandatory) {
            const isOtherSelected = currentValues.resultDisclosureFormats?.find(
              ({ resultDisclosureFormats }) =>
                resultDisclosureFormats?.value.trim() === 'Other (described below)',
            )?.isSelected;

            const isOtherMilestoneSelected = currentValues.resultsDisclosureDueDate?.find(
              ({ dateType }) => dateType?.value === 'Other Alternate milestone (defined below)',
            )?.isSelected;

            if (isOtherSelected && !values.otherInfo?.trim()) {
              errors.otherInfo = 'Please enter other information';
            }

            if (isOtherMilestoneSelected && !values.alternateTrialMilestone?.trim()) {
              errors.alternateTrialMilestone = 'Please enter the description';
            }
            if (
              currentValues?.resultDisclosureUpdateType &&
              currentValues?.resultDisclosureUpdateType.length > 0 &&
              tab?.tabId
            ) {
              const isUpdateOtherSelected = currentValues?.resultDisclosureUpdateType?.find(
                ({ updateType, tabId }) =>
                  updateType.value === 'Other updates' && tab.tabId === tabId,
              );
              if (isUpdateOtherSelected && !otherUpdateTypeDesc?.trim())
                errors.otherUpdateTypeDesc = 'Please enter other information';
            }
            if (
              currentValues?.resultDisclosureUpdatesDue &&
              currentValues?.resultDisclosureUpdatesDue.length > 0 &&
              tab?.tabId
            ) {
              const isDuesOtherSelected = currentValues?.resultDisclosureUpdatesDue?.find(
                ({ updatesDue, tabId }) => updatesDue?.value === 'Other' && tab.tabId === tabId,
              );
              if (isDuesOtherSelected && !otherUpdateDueDesc?.trim())
                errors.otherUpdateDueDesc = 'Please enter other information';
            }
          }
        }
      } else {
        if (values.isResultsPosted) {
          if (!checkIsModalSelected(values.resultDisclosureFormats, currentValues.isMandatory)) {
            errors.resultDisclosure = 'Please select result disclosure formats';
          }
          if (
            !checkIsModalDateSelected(values.resultsDisclosureDueDate, currentValues.isMandatory)
          ) {
            errors.resultsDisclosureDueDate = 'Please select result disclosure due date';
          }

          if (currentValues.isMandatory) {
            const isOtherSelected = values.resultDisclosureFormats?.find(
              ({ resultDisclosureFormats }) =>
                resultDisclosureFormats?.value.trim() === 'Other (described below)',
            )?.isSelected;

            const isOtherMilestoneSelected = values.resultsDisclosureDueDate?.find(
              ({ dateType }) => dateType?.value === 'Other Alternate milestone (defined below)',
            )?.isSelected;

            if (isOtherSelected && !values.otherInfo?.trim()) {
              errors.otherInfo = 'Please enter other information';
            }

            if (isOtherMilestoneSelected && !values.alternateTrialMilestone?.trim()) {
              errors.alternateTrialMilestone = 'Please enter the description';
            }
            if (
              currentValues?.resultDisclosureUpdateType &&
              currentValues?.resultDisclosureUpdateType.length > 0
            ) {
              const isUpdateOtherSelected = currentValues?.resultDisclosureUpdateType?.find(
                ({ value }) => value === 'Other (list below)',
              )?.isChecked;
              if (isUpdateOtherSelected && !otherUpdateTypeDesc?.trim())
                errors.otherUpdateTypeDesc = 'Please enter other information';
            }
            if (
              currentValues?.resultDisclosureUpdatesDue &&
              currentValues?.resultDisclosureUpdatesDue.length > 0
            ) {
              const isDuesOtherSelected = currentValues?.resultDisclosureUpdatesDue?.find(
                ({ value }) => value === 'Other',
              )?.isChecked;
              if (isDuesOtherSelected && !otherUpdateDueDesc?.trim())
                errors.otherUpdateDueDesc = 'Please enter other information';
            }
          }
        }
      }
    }
  }

  return errors;
};
