import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { TEXT_LIMIT, VALIDATION_REGEX } from '../../../../utils/generic/constants';
import {
  checkIngestionActionForMandatoryFields,
  isValidDate,
} from '../../../../utils/generic/helper';

export const validate = (values, nextReviewDate) => {
  const errors = {};
  const executiveSummary = values.executiveSummary.replace(/(\r\n|\n|\r)/gm, '').trim();
  const updateDesc = values.reasonForUpdateDescription?.trim() || values.updateDesc?.trim();
  if (!values.reasonForUpdate) {
    errors.reasonForUpdate = 'Please enter reason for update';
  }
  if (!updateDesc) {
    errors.reasonForUpdateDescription = 'Please enter the description';
  }
  if (updateDesc && updateDesc.length > 400) {
    errors.reasonForUpdateDescription = 'Please enter only 400 characters';
  }
  if (!values.executiveSummary?.trim()) {
    errors.executiveSummary = 'Please enter the excutive Summary';
  }
  if (!values.repealedStatus) {
    errors.repealedStatus = 'Please select the repealed status';
  }
  // if (values.executiveSummary?.trim() && executiveSummary.trim().length > TEXT_LIMIT[7000]) {
  //   errors.executiveSummary = 'Please enter only 7000 characters';
  // }
  if (nextReviewDate && !isValidDate(nextReviewDate)) {
    errors.nextFullReviewDate = 'Please enter a valid date (dd-MMM-yyyy)';
  }
  return errors;
};

export const layoutSchema = {
  layout: 'row',
  className: 'record-update-container',
  spacing: 2,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'row',
      size: 12,
      className: 'reason-for-update',
      // spacing: 3,
      type: fieldTypes.select,
      options: [],
      props: {
        height: 'ht-lg',
      },
      content: [
        {
          layout: 'reasonForUpdate',
          size: 5,
          className: '',
        },
      ],
    },
    {
      layout: 'row',
      className: 'description',
      size: 12,
      // spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'reasonForUpdateDescription',
          size: 5,
          className: '',
        },
      ],
    },
    {
      layout: 'row',
      className: 'description',
      size: 12,
      // spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'repealedStatus',
          size: 5,
          className: '',
        },
      ],
    },
    {
      layout: 'row',
      className: 'executive-Summary',
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'executiveSummary',
          // className: 'person-suffix',
        },
      ],
    },
  ],
};

export const formSchema = {
  reasonForUpdate: {
    name: 'reasonForUpdate',
    type: fieldTypes.select,
    label: 'REASON FOR UPDATE',
    props: {
      height: 'ht-lg',
      isMandatory: true,
      isSearchable: true,
      isClearable: true,
    },
  },
  reasonForUpdateDescription: {
    name: 'reasonForUpdateDescription',
    type: fieldTypes.text,
    label: 'REASON FOR UPDATE - DESCRIPTION',
    props: {
      isMandatory: true,
    },
  },
  repealedStatus: {
    name: 'repealedStatus',
    type: fieldTypes.custom_radio,
    label: 'Repealed Status',
    options: [
      { id: 1, value: 'ACTIVE', display: 'ACTIVE' },
      { id: 2, value: 'REPEALED', display: 'REPEALED' },
    ],
    props: {
      isMandatory: true,
    },
  },
  executiveSummary: {
    name: 'executiveSummary',
    type: fieldTypes.mdEditor,
    label: 'EXECUTIVE SUMMARY',
    props: {
      rowsMin: 10,
      rowsMax: 5,
      cols: 150,
      isMandatory: true,
    },
  },
};

export const repealedStatusMap = { 1: 'Active', 2: 'Repealed' };
