import { fieldTypes } from '../../framework/formBuilder/utils';
import { DROPDOWN_TYPES, TEXT_LIMIT } from '../../utils/generic/constants';
import format from 'date-fns/format';

export const validate = (values, isValidate, isOthertab) => {
  const errors = {};
  const clinicalTrailGeneralInfoInternalComments = values?.clinicalTrailGeneralInfoInternalComments
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  if (
    values?.otherTimelineAuthorityReview?.trim() &&
    values?.otherTimelineAuthorityReview?.trim().length > TEXT_LIMIT[150]
  ) {
    errors.otherTimelineAuthorityReview = 'Please enter only 150 characters';
  }
  if (
    values?.furtherDetailsOnAutomaticAcceptance?.trim() &&
    values?.furtherDetailsOnAutomaticAcceptance?.trim().length > TEXT_LIMIT[400]
  ) {
    errors.furtherDetailsOnAutomaticAcceptance = 'Please enter only 400 characters';
  }
  if (
    values?.otherOrderOfSubmission?.trim() &&
    values?.otherOrderOfSubmission?.trim().length > TEXT_LIMIT[400]
  ) {
    errors.otherOrderOfSubmission = 'Please enter only 400 characters';
  }
  if (values?.feesForCta?.trim() && values?.feesForCta?.trim().length > TEXT_LIMIT[1000]) {
    errors.feesForCta = 'Please enter only 1000 characters';
  }
  if (
    values?.clinicalTrailGeneralInfoInternalComments?.trim() &&
    clinicalTrailGeneralInfoInternalComments?.trim().length > TEXT_LIMIT[7000]
  ) {
    errors.clinicalTrailGeneralInfoInternalComments = 'Please enter only 7000 characters';
  }
  if (
    values?.regulatoryAuthorityScope?.trim() &&
    values?.regulatoryAuthorityScope?.trim().length > TEXT_LIMIT[1000]
  ) {
    errors.regulatoryAuthorityScope = 'Please enter only 1000 characters';
  }
  if (
    values?.authorityForClinicalSubmission?.trim() &&
    values?.authorityForClinicalSubmission?.trim().length > TEXT_LIMIT[1000]
  ) {
    errors.authorityForClinicalSubmission = 'Please enter only 1000 characters';
  }
  if (
    values?.clinicalTrialAuthorizationDepartment?.trim() &&
    values?.clinicalTrialAuthorizationDepartment?.trim().length > TEXT_LIMIT[400]
  ) {
    errors.clinicalTrialAuthorizationDepartment = 'Please enter only 400 characters';
  }
  if (
    values?.responsibilityOfRegulatoryDepartment?.trim() &&
    values?.responsibilityOfRegulatoryDepartment?.trim().length > TEXT_LIMIT[1000]
  ) {
    errors.responsibilityOfRegulatoryDepartment = 'Please enter only 1000 characters';
  }
  if (values?.authorityInCharge?.trim() && values?.authorityInCharge.trim().length > 400) {
    errors.authorityInCharge = 'Please enter only 400 characters';
  }
  if (
    values?.otherDocSubmissionType?.trim() &&
    values?.otherDocSubmissionType.trim().length > 400
  ) {
    errors.otherDocSubmissionType = 'Please enter only 400 characters';
  }
  if (isValidate) {
    if (values?.isConductOfClinicalTrial === null) {
      errors.isConductOfClinicalTrial = 'Please select conduct of Clinical trial';
    }
    // if (values?.authorityReviewTimeline === 'Other') {
    //   if (!values?.otherTimelineAuthorityReview?.trim()) {
    //     errors.otherTimelineAuthorityReview = 'Please enter other timeline authority review';
    //   }
    // }
    // if (values?.isConductOfClinicalTrial) {
    //   if (values?.country?.length === 0) {
    //     errors.country = 'Please select country';
    //   }
    //   if (values?.keyDocumentClinicalTrialSubmission?.length === 0) {
    //     errors.keyDocumentClinicalTrialSubmission = 'Please select key documents';
    //   }
    //   if (!values?.isCheck) {
    //     if (!values?.regulatoryAuthorityScope?.trim()) {
    //       errors.regulatoryAuthorityScope = 'Please enter regulatory scope';
    //     }

    //     if (!values?.authorityForClinicalSubmission?.trim()) {
    //       errors.authorityForClinicalSubmission =
    //         'Please enter to  which authority do clinical trial submission need to be sent';
    //     }

    //     if (!values?.authorityInCharge?.trim()) {
    //       errors.authorityInCharge = 'Please enter authority incharge';
    //     }
    //   }
    //   if (
    //     values?.linkingCTRegulationDoc === null ||
    //     values?.linkingCTRegulationDoc?.value === null
    //   ) {
    //     errors.linkingCTRegulationDoc = 'Please select valid data';
    //   }
    //   if (isOthertab) {
    //     if (!values?.ctaSubmissionSourceUrl?.length) {
    //       errors.sourceUrl = 'Please enter source url';
    //     }
    //   }
    //   if (values?.clinicalTrialLanguages?.length === 0) {
    //     errors.clinicalTrialLanguages = 'Please select language';
    //   }
    // }
    // if (isOthertab) {
    //   const isOtherSelected = values.requirementSubmissionType?.find(
    //     ({ submissionType }) => submissionType?.value.trim() === 'Other',
    //   );
    //   if (isOtherSelected && !values?.otherDocSubmissionType?.trim()) {
    //     errors.otherDocSubmissionType = 'Please enter other document submission type';
    //   }
    // } else {
    //   const isOtherSelected = values.requirementSubmissionType?.find(
    //     ({ label }) => label === 'Other',
    //   );
    //   if (isOtherSelected && !values?.otherDocSubmissionType?.trim()) {
    //     errors.otherDocSubmissionType = 'Please enter other document submission type';
    //   }
    // }
    // if (isOthertab) {
    //   const isOtherSelected = values.requirementSubmissionType?.find(
    //     ({ submissionType }) => submissionType?.value.trim() === 'Other',
    //   );
    //   if (isOtherSelected && !values?.otherDocSubmissionType?.trim()) {
    //     errors.otherDocSubmissionType = 'Please enter other document submission type';
    //   }
    // } else {
    //   const isOtherSelected = values.requirementSubmissionType?.find(
    //     ({ label }) => label === 'Other',
    //   );
    //   if (isOtherSelected && !values?.otherDocSubmissionType?.trim()) {
    //     errors.otherDocSubmissionType = 'Please enter other document submission type';
    //   }
    // }
  }
  return errors;
};

const columnDefs = [
  {
    headerName: 'DOCUMENT ID',
    field: 'id',
    width: 250,
    sortable: true,
    cellRenderer: 'DefaultCellRender',
  },
  {
    headerName: 'DOCUMENT TITLE',
    field: 'documentTitle',
    width: 400,
  },
  {
    headerName: 'Regulatory Date',
    field: 'regulatoryDate',
    width: 350,
    sortable: true,
    cellRenderer: ({ value }) => {
      return value ? format(new Date(value), 'dd-MMM-yyyy') : 'NA';
    },
  },
];

export const layoutSchema = {
  layout: 'row',
  spacing: 1,
  alignItems: 'flex-start',
  justify: 'flex-start',
  content: [
    {
      layout: 'isConductOfClinicalTrial',
      size: 4,
    },
    {
      layout: 'country',
      size: 4,
    },
    {
      layout: 'authorityInCharge',
      size: 4,
    },
    {
      layout: 'regulatoryAuthorityScope',
      size: 6,
    },
    {
      layout: 'clinicalTrialAuthorizationDepartment',
      size: 6,
    },
    {
      layout: 'responsibilityOfRegulatoryDepartment',
      size: 6,
    },
    {
      layout: 'linkingCTRegulationDoc',
      size: 6,
    },
    {
      layout: 'empty',
      size: 8,
    },
    {
      layout: 'linkingCTRegulationTable',
      size: 12,
      className: 'linkingCTRegulationTable',
    },
    {
      layout: 'fullNameofCT',
      size: 4,
    },
    {
      layout: 'languages',
      size: 4,
    },
    {
      layout: 'currentVersion',
      size: 4,
    },
    {
      layout: 'authorityForClinicalSubmission',
      size: 8,
    },
    {
      layout: 'keyDocumentClinicalTrialSubmission',
      size: 4,
    },
  ],
};

export const formSchema = {
  isConductOfClinicalTrial: {
    label: 'Are there any Requirements for the conduct of Clinical Trial in this country?',
    name: 'isConductOfClinicalTrial',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: true,
    },
  },
  country: {
    name: 'country',
    type: fieldTypes.select_tag,
    label: 'To what country, countries or regions is this regulation or requirement applicable?',
    options: [],
    props: {
      height: 'ht-lg',
      // isSearchable: true,
      // isClearable: true,
      // isMulti: true,
      // showSelectedData: true,
      // type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
    },
  },
  authorityInCharge: {
    label:
      'What authority, agency, association or group is responsible for the clinical trial regulation?',
    name: 'authorityInCharge',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
    },
  },
  keyDocumentClinicalTrialSubmission: {
    name: 'keyDocumentClinicalTrialSubmission',
    type: fieldTypes.select_async,
    label: 'Key documents for clinical trial submission',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      isMulti: true,
      showSelectedData: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
    },
  },
  regulatoryAuthorityScope: {
    label: 'Regulatory authority scope',
    name: 'regulatoryAuthorityScope',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
    },
  },
  clinicalTrialAuthorizationDepartment: {
    label: 'Responsible department for clinical trial authorization',
    name: 'clinicalTrialAuthorizationDepartment',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
    },
  },
  responsibilityOfRegulatoryDepartment: {
    label: 'Responsibility of regulatory department',
    name: 'responsibilityOfRegulatoryDepartment',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
    },
  },
  authorityForClinicalSubmission: {
    label: 'To which authority do clinical trial submission need to be sent?',
    name: 'authorityForClinicalSubmission',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
    },
  },
  linkingCTRegulationDoc: {
    name: 'linkingCTRegulationDoc',
    type: fieldTypes.select_async,
    label: 'Linking to Clinical Trial regulation',
    props: {
      isSearchable: true,
      isClearable: true,
      isMulti: false,
      showSelectedData: false,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
    },
  },
  linkingCTRegulationTable: {
    name: 'linkingCTRegulationTable',
    type: fieldTypes.dndDataGrid,
    props: {
      columnDefs,
      rowHeight: 35,
    },
  },
  fullNameofCT: {
    label: 'Full (formal) name of the clinical trial regulation',
    name: 'fullNameofCT',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      disabled: true,
      placeholder: '',
    },
  },
  languages: {
    name: 'languages',
    type: fieldTypes.textArea,
    label: 'Language(s) in which the CT regulation is written',
    props: {
      rowsMin: 2.4,
      disabled: true,
      placeholder: '',
    },
  },
  currentVersion: {
    label: 'Current version or revision number of the regulation/requirement',
    name: 'currentVersion',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      disabled: true,
      placeholder: '',
    },
  },
  empty: {},
};

export const requirementUrlStatus = [
  {
    id: 1,
    value: 'Active',
  },
  {
    id: 2,
    value: 'InActive',
  },
  {
    id: 3,
    value: 'Broken',
  },
];

export const layoutSchema1 = {
  layout: 'row',
  spacing: 2,
  alignItems: 'flex-start',
  justify: 'flex-start',
  content: [
    {
      layout: 'requirementSubmissionType',
      size: 4,
    },
    {
      layout: 'otherDocSubmissionType',
      size: 8,
    },
    {
      layout: 'clinicalTrialLanguages',
      size: 4,
    },
    {
      layout: 'authorityReviewTimeline',
      size: 5,
      className: 'timeline',
    },
    {
      layout: 'otherTimelineAuthorityReview',
      size: 5,
    },
    {
      layout: 'automaticAccetance',
      size: 5,
      className: 'timeline',
    },
    {
      layout: 'furtherDetailsOnAutomaticAcceptance',
      size: 5,
    },
    {
      layout: 'orderOfSubmission',
      size: 6,
      className: 'timeline',
    },
    {
      layout: 'otherOrderOfSubmission',
      size: 5,
      //className: 'timeline',
    },
    {
      layout: 'feesForCta',
      size: 6,
      //className: 'timeline',
    },
  ],
};
export const submissionOption = [
  {
    id: 1,
    value: 'Concurrent',
    label: 'Concurrent',
  },
  {
    id: 2,
    value: 'Parallel',
    label: 'Parallel',
  },
  {
    id: 3,
    value: 'Sequential-first',
    label: 'Sequential - first',
  },
  {
    id: 4,
    value: 'Sequential-second',
    label: 'Sequential - second',
  },
  {
    id: 5,
    value: 'Other',
    label: 'Other',
  },
];

export const formSchema1 = {
  requirementSubmissionType: {
    label: 'Document submission type',
    name: 'requirementSubmissionType',
    type: fieldTypes.select_async,
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      isMulti: true,
      showSelectedData: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
    },
  },
  otherDocSubmissionType: {
    name: 'otherDocSubmissionType',
    type: fieldTypes.textArea,
    label: 'If Other - Document submission type',
    props: {
      rowsMin: 2.4,
      placeholder: '',
    },
  },
  clinicalTrialLanguages: {
    name: 'clinicalTrialLanguages',
    type: fieldTypes.select_tag,
    label: 'Language(s) in which data can be reported',
    props: {
      isMandatory: false,
    },
  },
  authorityReviewTimeline: {
    name: 'authorityReviewTimeline',
    label: 'Timelines for authority review',
    type: fieldTypes.radio,
    options: [
      {
        id: 1,
        value: '30 Days',
        label: '30 Days',
      },
      {
        id: 2,
        value: '45 Days',
        label: '45 Days',
      },
      {
        id: 3,
        value: '60 Days',
        label: '60 Days',
      },
      {
        id: 4,
        value: '90 Days',
        label: '90 Days',
      },
      {
        id: 5,
        value: 'Other',
        label: 'Other',
      },
    ],
    props: {
      type: 'group',
      className: 'timelineRadioClass',
    },
  },
  otherTimelineAuthorityReview: {
    name: 'otherTimelineAuthorityReview',
    type: fieldTypes.textArea,
    label: 'If Other - Timelines for authority review',
    props: {
      rowsMin: 2.4,
      placeholder: '',
    },
  },
  automaticAccetance: {
    label: 'Automatic acceptance after specified time of review?',
    name: 'automaticAccetance',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {},
  },
  furtherDetailsOnAutomaticAcceptance: {
    name: 'furtherDetailsOnAutomaticAcceptance',
    type: fieldTypes.textArea,
    label: 'Further details on automatic acceptance ',
    props: {
      rowsMin: 2.4,
      placeholder: '',
    },
  },
  orderOfSubmission: {
    name: 'orderOfSubmission',
    label: 'Order of submission',
    type: fieldTypes.radio,
    options: submissionOption,
    props: {
      type: 'group',
      className: 'timelineRadioClass',
    },
  },
  otherOrderOfSubmission: {
    name: 'otherOrderOfSubmission',
    type: fieldTypes.textArea,
    label: 'If Other - Order of submission',
    props: {
      rowsMin: 2.4,
      placeholder: '',
    },
  },
  feesForCta: {
    name: 'feesForCta',
    type: fieldTypes.textArea,
    label: 'Fees for clinical trial application submission',
    props: {
      rowsMin: 2.4,
      placeholder: '',
    },
  },
};

export const timelinesOptObj = {
  '30 Days': 1,
  '45 Days': 2,
  '60 Days': 3,
  '90 Days': 4,
  Other: 5,
};

const columnDefs1 = [
  {
    headerName: 'DOCUMENT ID',
    field: 'value',
    width: 250,
    sortable: true,
    cellRenderer: 'DefaultCellRender',
  },
  {
    headerName: 'DOCUMENT TITLE',
    field: 'documentTitle',
    width: 400,
  },
  {
    headerName: 'Regulatory Date',
    field: 'regulatoryDate',
    width: 350,
    sortable: true,
    cellRenderer: ({ value }) => {
      return value ? format(new Date(value), 'dd-MMM-yyyy') : 'NA';
    },
  },
];
export const acceptanceTimeline = {
  Concurrent: 1,
  Parallel: 2,
  'Sequential-first': 3,
  'Sequential-second': 4,
  Other: 5,
};

export const layoutSchema2 = {
  layout: 'row',
  spacing: 1,
  alignItems: 'flex-start',
  justify: 'flex-start',
  content: [
    {
      layout: 'sourceDocs',
      size: 4,
      className: 'sourceDocs',
    },
    {
      layout: 'empty',
      size: 8,
    },
    {
      layout: 'sourceDocsTable',
      size: 12,
      className: 'sourceDocsTable',
    },
    {
      layout: 'clinicalTrailGeneralInfoInternalComments',
      size: 12,
    },
  ],
};

export const formSchema2 = {
  sourceDocs: {
    name: 'sourceDocs',
    type: fieldTypes.select_async,
    label: 'ADDITIONNAL LINKING TO Other Source Documents',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      isMulti: true,
      showSelectedData: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
    },
  },
  sourceDocsTable: {
    name: 'sourceDocsTable',
    type: fieldTypes.dndDataGrid,
    props: {
      columnDefs: columnDefs1,
      rowHeight: 35,
    },
  },
  clinicalTrailGeneralInfoInternalComments: {
    name: 'clinicalTrailGeneralInfoInternalComments',
    type: fieldTypes.textArea,
    label: 'INTERNAL COMMENTS',
    props: {
      rowsMin: 10,
      rowsMax: 5,
      cols: 150,
    },
  },
  empty: {},
};
