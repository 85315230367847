/*eslint new-cap: ["error", { "capIsNew": false }]*/
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { isEmpty, omit, isEqual, sortBy } from 'lodash';
import { fieldTypes } from '../../../../../framework/formBuilder/utils';
import { FormBuilder, Modal } from '../../../../../framework';
import { searchCountry, searchState, searchCity } from '../../../../../utils/generic/helper';
import {
  mapFormDataToStore,
  mapRowDataToForm,
  checkAddressChanges,
} from '../../../../../store/helpers/organization';
import ConfirmationMessage from '../../../../../generic/modals/ConfirmationMessage';
import { showModal } from '../../../../../store/actions/globalActions';
import { MODAL_MESSAGES } from '../../../../../utils/generic/constants';
import { validateForm, layoutSchema, formSchema, validateOnChange } from '../config';
import './index.scss';

const AddressModal = ({ row: { rowIndex, rowData }, onClose, handleValidate }) => {
  const isPrimaryRow = Boolean(rowData.isPrimary);
  let initialValues = { ...omit(rowData, ['isPrimary']) };
  initialValues = mapRowDataToForm(initialValues);
  const parsedInitialValues = { ...mapFormDataToStore(initialValues) };
  const dispatch = useDispatch();
  const [showconfirmation, setShowConfirmation] = useState(false);
  const [showEditModal, setShowEditModal] = useState(true);
  const [showInlineError, setShowInlineError] = useState(false);
  const [showCosmeticPopUp, setShowCosmeticPopUp] = useState(false);

  const formRef = useRef(null);

  const handleCountryChange = () => {
    formRef.current.setFieldValue('state', null);
    formRef.current.setFieldValue('city', null);
  };
  const handleStateChange = () => {
    formRef.current.setFieldValue('city', null);
  };

  const filterCountry = async value => {
    const filteredData = await searchCountry(value);
    if (!filteredData) {
      dispatch(showModal(MODAL_MESSAGES.API_ERROR));
    }
    return filteredData;
  };
  const filterState = async value => {
    const country = formRef.current && formRef.current.values.country;
    let filteredData = [];
    if (country && country.value) {
      filteredData = await searchState(value, country.value);
      if (!filteredData) {
        dispatch(showModal(MODAL_MESSAGES.API_ERROR));
      }
    }
    return filteredData;
  };
  const filterCity = async value => {
    const state = formRef.current && formRef.current.values.state;
    let filteredData = [];
    if (state && state.value) {
      filteredData = await searchCity(value, state.value);
      if (!filteredData) {
        dispatch(showModal(MODAL_MESSAGES.API_ERROR));
      }
    }
    return filteredData;
  };

  const triggerAddressDoctor = () => {
    let formData = formRef.current.values;
    const { latitude, longitude } = convertGeoToLatLong(formData);
    formData = {
      rowIndex,
      rowData: { ...formData, isPrimary: isPrimaryRow, latitude, longitude },
    };
    const isChanged = checkAddressChanges(parsedInitialValues, {
      ...mapFormDataToStore(formData.rowData),
    });
    formData = isChanged
      ? {
          ...formData,
          rowData: {
            ...formData.rowData,
            isValidated: false,
            validationCode: 'E',
            validationDescription: null,
          },
        }
      : formData;
    handleValidate(formData);
  };

  const handleValidateBind = async () => {
    try {
      const checkForErrors = validateForm(formRef.current.values);
      if (isEmpty(checkForErrors)) {
        let formData = formRef.current.values;
        const isChanged = checkAddressChanges(parsedInitialValues, {
          ...mapFormDataToStore(formData),
        });
        if (!formData.validationCode || formData.validationCode === 'E') {
          triggerAddressDoctor();
        } else if (isChanged) {
          setShowInlineError(false);
          setShowCosmeticPopUp(true);
          setShowEditModal(false);
        } else {
          setShowInlineError(true);
        }
      } else {
        handleSubmit({ isAddBtn: false })(formRef.current.values, formRef.current);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddClick = () =>
    handleSubmit({ isAddBtn: true })(formRef.current.values, formRef.current);

  let updatedFormSchema = {
    ...formSchema({
      handleCountryChange,
      handleStateChange,
      handleValidate: handleValidateBind,
      filterCountry,
      filterState,
      filterCity,
      handleAddClick,
    }),
  };
  updatedFormSchema = {
    ...updatedFormSchema,
    addButton: {
      ...updatedFormSchema.addButton,
      label: 'SAVE',
      props: {
        onClick: handleAddClick,
      },
    },
    subRegion: {
      name: 'subRegion',
      type: fieldTypes.text,
      label: 'Sub Region',
      options: [],
      props: {
        isMandatory: false,
        inputHeight: 'ht-lg',
      },
    },
  };

  const convertGeoToLatLong = values => {
    const { geoLocation } = values;
    let obj = {
      latitude: null,
      longitude: null,
    };
    if (geoLocation) {
      const geoArr = geoLocation.split(',');
      obj.latitude = geoArr.length && geoArr[0] ? geoArr[0] : null;
      obj.longitude = geoArr.length && geoArr[1] ? geoArr[1] : null;
    }
    return obj;
  };

  const editSaveBtnClick = values => {
    let formValues = values;
    const isChanged = checkAddressChanges(parsedInitialValues, formValues);
    formValues = isChanged
      ? {
          ...formValues,
          isValidated: false,
          validationCode: 'E',
          validationDescription: null,
        }
      : formValues;
    onClose({
      rowData: { ...formValues, isPrimary: rowData.isPrimary },
      rowIndex,
    });
  };
  const handleSaveClosePopUp = isConfim => {
    if (isConfim) {
      let values = formRef.current.values;
      const { latitude, longitude } = convertGeoToLatLong(values);
      let parsed = { ...mapFormDataToStore({ ...values, latitude, longitude }) };
      editSaveBtnClick(parsed);
    } else {
      setShowConfirmation(false);
      setShowEditModal(true);
    }
  };

  const handleCosmeticClosePopUp = isConfim => {
    if (isConfim) {
      triggerAddressDoctor();
    } else {
      setShowCosmeticPopUp(false);
      setShowEditModal(true);
    }
  };
  const idCheck = (ref, act) => {
    if (isEmpty(ref) && isEmpty(act)) {
      return true;
    } else if (!isEmpty(ref) && !isEmpty(act)) {
      return isEqual(ref.id, act.id);
    } else {
      return false;
    }
  };

  const copareObjects = (values, parsed) => {
    let {
      street1,
      street2,
      city,
      state,
      country,
      postalCode,
      phoneNumbers,
      faxNumbers,
      emails,
      websites,
      geoLocation,
      subRegion,
    } = values;
    let isDataExists = false;
    if (
      isEqual(parsed.street1, street1) &&
      isEqual(parsed.street2, street2) &&
      idCheck(parsed.city, city) &&
      idCheck(parsed.state, state) &&
      idCheck(parsed.country, country) &&
      isEqual(parsed.postalCode, postalCode) &&
      isEqual(parsed.geoLocation, geoLocation) &&
      isEqual(sortBy(parsed.emails), sortBy(emails)) &&
      isEqual(sortBy(parsed.faxNumbers), sortBy(faxNumbers)) &&
      isEqual(sortBy(parsed.websites), sortBy(websites)) &&
      isEqual(sortBy(parsed.phoneNumbers), sortBy(phoneNumbers)) &&
      isEqual(parsed.subRegion, subRegion)
    ) {
      isDataExists = true;
    }
    return isDataExists;
  };
  const handleSubmit = ({ isAddBtn }) => (values, { setFieldTouched, setErrors }) => {
    const customErrors = validateForm(values, isAddBtn);
    if (isEmpty(customErrors)) {
      const { latitude, longitude } = convertGeoToLatLong(values);
      const parsed = { ...mapFormDataToStore({ ...values, latitude, longitude }) };
      const addressChanged = copareObjects(parsedInitialValues, parsed);
      if (addressChanged) {
        setShowEditModal(false);
        setShowConfirmation(true);
      } else {
        editSaveBtnClick(parsed);
      }
    } else {
      for (let key in customErrors) {
        if (Object.prototype.hasOwnProperty.call(customErrors, key)) {
          setFieldTouched(key, true, false);
        }
      }
      setErrors(customErrors);
    }
  };

  return (
    <>
      {showEditModal && (
        <Modal isOpen onClose={onClose} heading="EDIT">
          <div className="org-address-modal">
            <Formik
              initialValues={initialValues}
              validate={async values =>
                validateOnChange({ values, errors: { ...formRef.current.errors } })
              }
              onSubmit={() => {}}
            >
              {formikProps => {
                formRef.current = formikProps;
                return (
                  <>
                    <FormBuilder
                      formikProps={formikProps}
                      layoutSchema={layoutSchema(true)}
                      formSchema={updatedFormSchema}
                      onSubmitValidationError={() => {}}
                    />
                  </>
                );
              }}
            </Formik>
          </div>
          {showInlineError && <p className="error-text">No changes were made to the address.</p>}
        </Modal>
      )}
      {showconfirmation && (
        <ConfirmationMessage
          isOpen
          onClose={handleSaveClosePopUp}
          message={`No changes were made, current value will be retained.`}
        />
      )}
      {showCosmeticPopUp && (
        <ConfirmationMessage
          isOpen
          onClose={handleCosmeticClosePopUp}
          message={`If you edit this address, validation status will change and address will have to re-validate again at an additional cost. Cosmetic changes, like changing St to street or Ave to Avenue, etc is not a valid reason for editing an address. Do you want to proceed?`}
        />
      )}
    </>
  );
};

AddressModal.propTypes = {
  row: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
};

export default AddressModal;
