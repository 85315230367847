import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
// const  Modal from './modal/index';
const Modal = React.lazy(() => import('./modal/index'));

const CopyContentModal = ({ children, lineCount, renderCellValue }) => {
  const [showCopyButton, setShowCopyButton] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const cellContentRef = useRef(null);

  // eslint-disable-next-line no-mixed-operators
  const minTopAfterPseudo = 21 * lineCount - 10;
  // (line height * no of lines) - 10

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showModal = event => {
    event.stopPropagation();
    const isShowDotsEle = event.target.classList.contains('show-dots');
    if (isShowDotsEle && event.nativeEvent.offsetX > cellContentRef.current.offsetWidth - 20) {
      handleClick(event);
      setShowCopyButton(true);
    }
  };

  const onMouseOverEvent = event => {
    event.target.classList.add('active-element');
    const afterTopPos = window
      .getComputedStyle(document.querySelector('.active-element'), ':after')
      .getPropertyValue('top');

    if (afterTopPos && afterTopPos.indexOf('px') != -1) {
      if (parseInt(afterTopPos.replace('px', ''), 10) >= minTopAfterPseudo) {
        event.target.classList.add('show-dots');
      }
    }
  };

  const onMouseOutEvent = event => {
    event.target.classList.remove('active-element', 'show-dots');
  };

  return (
    <>
      <div
        className={`cellContent-default ${lineCount ? `line-${lineCount}` : ''}`}
        onMouseOver={onMouseOverEvent}
        onMouseOut={onMouseOutEvent}
        onClick={showModal}
        ref={cellContentRef}
      >
        {renderCellValue ? renderCellValue : children}
      </div>
      {showCopyButton && (
        <Modal text={renderCellValue ? renderCellValue : children} anchorEl={anchorEl} handleClose={handleClose}></Modal>
      )}
    </>
  );
};

CopyContentModal.propTypes = {
  children: PropTypes.any,
  lineCount: PropTypes.number,
  renderCellValue: PropTypes.any,
};

CopyContentModal.defaultProps = {
  lineCount: 2,
  renderCellValue: '',
};

export default React.memo(CopyContentModal);
